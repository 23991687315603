import { useContext, useMemo, useRef, useState } from 'react';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Typography from 'components/commercetools-ui/typography';
import { LayoutContext } from 'frontastic/provider/layout';
import Carousel from '../carousel';
import styles from './cards-carousel.module.scss';

export interface ProductCarouselProps {
  title: string;
  items: any[];
  dataTestId?: string;
  classNameOverrides?: {
    item?: string;
  };
  displayCountDesktop?: boolean;
}

const CardsCarousel: React.FC<ProductCarouselProps> = ({
  title,
  items,
  dataTestId,
  classNameOverrides,
  displayCountDesktop = true,
}) => {
  const [currentCount, setCurrentCount] = useState<number>(0);
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const ref = useRef(null);
  const layout = useContext(LayoutContext);

  const onScroll = (event) => {
    setCurrentCount(event.currentCount);
    setScrollPercentage(event.scrollPercentage);
  };

  //Calculate if there is scroll then the scroll percentage will show in the progress bar
  const isScrollable = useMemo(() => {
    if (318 * items.length + 50 < layout.pageWidth) {
      return false;
    } else {
      return true;
    }
  }, [scrollPercentage, items.length, layout]);

  return (
    <div data-testid={dataTestId ?? 'card-carousel'} className={styles.container}>
      <div className={styles.container_top}>
        <h2 data-testid="card-carousel_title" className={styles.title}>
          <Typography>{title}</Typography>
        </h2>
        {isScrollable && (
          <div className={styles.btnWrapper}>
            <button className={styles.btnLeft} onClick={() => ref.current.scrollByPage(-1)} aria-label="Scroll Left">
              <ArrowLeftIcon height={24} style={{ strokeWidth: '1px' }} />
            </button>
            {!(displayCountDesktop === false && !layout.isMobile) && (
              <div className={styles.count}>
                {currentCount + 1} / {items?.length}
              </div>
            )}
            <button className={styles.btnRight} onClick={() => ref.current.scrollByPage(1)} aria-label="Scroll Right">
              <ArrowRightIcon height={24} style={{ strokeWidth: '1px' }} />
            </button>
          </div>
        )}
      </div>
      {items && items.length > 0 && (
        <div data-testid="card-items">
          <Carousel
            ref={ref}
            items={items}
            classNameOverrides={{
              items: styles.carouselItems,
              item: classNames(classNameOverrides?.item, styles.carouselItem),
            }}
            onScroll={onScroll}
            showButtons={false}
          />
        </div>
      )}
      {isScrollable && (
        <div className={styles.progressBarContainer}>
          <div
            style={{
              width: `${scrollPercentage}%`,
              minWidth: `${100 / items.length}%`,
            }}
            data-testid="progressBar"
          ></div>
        </div>
      )}
    </div>
  );
};

export default CardsCarousel;
