import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import ChevronIcon from '../../../icons/chevron';
import styles from './carousel.module.scss';

export interface CarouselProps {
  items: JSX.Element[];
  showButtons?: boolean;
  classNameOverrides?: {
    items?: string;
    item?: string;
    btnLeft?: string;
    btnRight?: string;
  };
  onScroll?: (event) => void;
  ref?: ForwardedRef<HTMLDivElement>;
}

const CarouselForwardedRef: ForwardRefRenderFunction<Element, CarouselProps> = (
  { onScroll, showButtons = true, classNameOverrides, items },
  ref: ForwardedRef<Element>,
) => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [shouldShowLeftButton, setShouldShowLeftButton] = useState<boolean>(false);
  const [shouldShowRightButton, setShouldShowRightButton] = useState<boolean>(false);
  const [currentCount, setCurrentCount] = useState<number>(1);
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    setShouldShowRightButton(carouselRef?.current?.scrollWidth > carouselRef?.current?.clientWidth);

    window.addEventListener('resize', handleScrollAndResize);
    return () => {
      window.removeEventListener('resize', handleScrollAndResize);
    };
  }, []);

  const scrollByPage = (direction: -1 | 1) => {
    const itemWidth = carouselRef.current.scrollWidth / items.length;

    setIsScrolling(true);
    carouselRef.current.scrollBy({
      left: direction * itemWidth,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setIsScrolling(false);
    }, 400);
  };
  const handleClick = (direction: -1 | 1) => {
    if (isScrolling) return;

    const itemWidth = carouselRef.current.scrollWidth / items.length;
    setIsScrolling(true);
    carouselRef.current.scrollBy({
      left: direction * itemWidth,
      behavior: 'smooth',
    });
    setTimeout(() => {
      setIsScrolling(false);
    }, 400);
  };
  const handleScrollAndResize = () => {
    const showLeftButton = carouselRef?.current?.scrollLeft !== 0;
    const showRightButton =
      carouselRef?.current?.scrollWidth > carouselRef?.current?.scrollLeft + carouselRef?.current?.clientWidth;
    const itemWidth = carouselRef.current.scrollWidth / items.length;

    setCurrentCount(Math.round(carouselRef?.current?.scrollLeft / itemWidth));
    setScrollPercentage(
      (100 * carouselRef?.current?.scrollLeft) /
        (carouselRef?.current?.scrollWidth - carouselRef?.current?.clientWidth),
    );
    setShouldShowLeftButton((leftBtn) => (showLeftButton !== leftBtn ? showLeftButton : leftBtn));
    setShouldShowRightButton((rightBtn) => (showRightButton !== rightBtn ? showRightButton : rightBtn));

    if (onScroll) {
      onScroll({
        scrollPercentage,
        currentCount,
        showLeftButton,
        showRightButton,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // not sure why but these errors terribly in Typescript, but all docs suggest doing what i have done
  useImperativeHandle(ref, () => {
    return {
      scrollByPage,
      currentCount,
    };
  });

  return (
    <>
      <div className={styles.container} data-testid="carousel">
        <div ref={carouselRef} className={styles.carousel} onScroll={handleScrollAndResize}>
          {shouldShowLeftButton && showButtons && (
            <button
              className={classNames(styles.btn, styles.btn_left, {
                [classNameOverrides?.btnLeft]: classNameOverrides?.btnLeft,
              })}
              onClick={() => handleClick(-1)}
              aria-label="Scroll Left"
            >
              <ChevronIcon className={classNames(styles.svg, styles.svg_left)}></ChevronIcon>
            </button>
          )}
          {shouldShowRightButton && showButtons && (
            <button
              className={classNames(styles.btn, styles.btn_right, {
                [classNameOverrides?.btnRight]: classNameOverrides?.btnRight,
              })}
              onClick={() => handleClick(1)}
              aria-label="Scroll Right"
            >
              <ChevronIcon className={classNames(styles.svg, styles.svg_right)}></ChevronIcon>
            </button>
          )}
          <div
            className={classNames(styles.items, {
              [classNameOverrides?.items]: !!classNameOverrides?.items,
            })}
          >
            {items?.map((item, index) => (
              <div key={index} className={classNames({ [classNameOverrides?.item]: !!classNameOverrides?.item })}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(CarouselForwardedRef);
