import { useContext, useEffect, useState } from 'react';
import CardsCarousel from 'components/commercetools-ui/products/cards-carousel';
import ProductCard from 'components/commercetools-ui/products/product-cards';
import { getProductsByQuery } from 'frontastic/actions/algolia';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './amplience-product-carousel.module.scss';

export interface AmplienceProductCarouselProps {
  content: {
    products: string[];
    title: string;
  };
}

const CAROUSEL_MAX_PRODUCTS = 8;
const AmplienceProductCarousel: React.FC<AmplienceProductCarouselProps> = ({ content }) => {
  const [products, setProducts] = useState([]);
  const { products: productIds, title } = content;
  const layout = useContext(LayoutContext);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    if (productIds?.length === 0) return;

    const requestQueryParams = {
      productIds: productIds,
      limit: CAROUSEL_MAX_PRODUCTS,
    };
    setProducts(await getProductsByQuery(requestQueryParams));
  };

  if (!products?.length) return null;
  return (
    <CardsCarousel
      title={title}
      items={products.map((product) => (
        <ProductCard key={product.productId} product={product} sizes={layout.isDesktop ? '25vw' : '80vw'} />
      ))}
      classNameOverrides={{ item: styles.productCardItem }}
      dataTestId="amplience-carousel"
      displayCountDesktop={false}
    />
  );
};

export default AmplienceProductCarousel;
